.header{
    height: 15rem;
    width: 100%;
}
.header h1{
    color: #fff;
    padding-bottom: 10px;
    text-shadow: 1px 4px 4px #000;
}
.header h2{
    color: #fff;
    padding-bottom: 15px;
    font-weight: 400;
}
.center-screen {
    position: relative;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: #fff;
}