.guess-container{
    padding-top: 10px;
    padding-bottom: 0;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-top: 1px solid #fff;
}
.guess{
    padding: 15px;
    border-radius: 100%;
    background: rgb(0,0,0);
    background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 66%);
}
@media only screen and (max-width: 428px){
    .guess{
        padding: 10px;
    }
}
h2{
    color: white;
}
.nodisplay{
    display: none;
}
svg{
    pointer-events: none;
}