body{
    margin: 0;
    padding: 0;
}
footer{
    position: fixed;
    margin-top: 33%;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #444;
    padding: 30px 0;
    color: #fff;
    text-align: center;
    margin-top: auto;
    z-index: 9999;
    background-color: #282c34;
}
footer p{
    color: #54575f;
}