body{
  background-color: #282c34;
  height: 100%;
}
.App {
  position: relative;
  text-align: center;
  min-height: 100vh;
  padding-bottom: 10%;
  overflow-x: hidden;
  box-sizing: border-box;
}
@media only screen and (max-width: 428px){
  .App{
    padding-bottom: 25%;
  }
}
.nodisplay{
  display: none;
}